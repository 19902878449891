@import '../assests.css';

.quote {
    width: 300px;
    color: #E1E1E1;
    text-align: right;
}
.quote__text {
    font: italic 400 16px basic-sans, Verdana, sans-serif;
}
.quote__author {
    font: normal 300 8px work-sans, Arial, sans-serif;
}