.main--communication .main__section__figure--Dolphin {
    display: none;
    margin-right: 0;
    margin-top: 5px;
}

.main--communication .main__figure--Dolphin-mobile {
    background: #FBF3E1;
    width: 100vw;
    min-height: 125vw;
    margin: 0;
    padding: 0;
}

.main--communication .main__section__p__headline {
    display: inline;
    color: #016AA3;
}

.main--communication .main__section__list-heading {
    font: normal 600 12px nazare, sans-serif;
    color: rgb(86, 127, 170);
}

.main--communication .main__section__list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.main--communication .main__section__list__ul {
    color: #9b9b9b;
}

.main--communication .main__section__p--last {
    margin-top: 64px;
    clear: both;
}

@media only screen and (min-width: 600px) {
    .main--communication .main__figure--Dolphin-mobile {
        display: none;
    }
    .main--communication .main__section__figure--Dolphin {
        display: inline-block;
        float: right;
        width: 240px;
        height: 300px;
        clear: right;
    }
}

@media only screen and (min-width: 768px) {
    .main--communication .main__section__list-heading {
        font-size: 14px;
    }
    .main--communication .main__section__list__ul {
        font-size: 22px;
    }
    .main--communication .main__section__list__ul li:after {
        content: '';
        min-width: 9px;
        min-height: 9px;
        width: 9px;
        height: 9px;
        background: #6698CC;
        border-radius: 10px;
        margin: 11px 0 0 5px;
    }
}

@media only screen and (min-width: 1060px) {
    .main--communication .main__section-background--blue {
        max-height: 500px;
    }
    .main--communication .main__section__figure--Dolphin {
        width: 480px;
        height: 600px;
    }
}