#viewport {
    bottom: 0;
    left: 0;
    overflow: hidden;
    perspective: 400;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  #world {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		-o-transform-style: preserve-3d;
    width: 100vw;
    height: 100vh;
  }

  #world div { 
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		-o-transform-style: preserve-3d;
  }

  .cloudBase {
    height: 20px;
    width: 20px;
    margin-left: -10px;
    margin-top: -10px;
    position: absolute;
    left: -25vw;
    top: 256px;
  }

  .cloudBase--right {
    left: 100vw;
  }

  .cloudBase--expired {
    opacity: 0;
    -webkit-transition: opacity 1s ease-out;
    -moz-transition: opacity 1s ease-out;
    -o-transition: opacity 1s ease-out;
  }

  .cloudLayer {
    height: 256px;
    left: 50%;
    margin-left: -128px;
    margin-top: -128px;
    position: absolute;
    top: 50%;
    width: 256px;

    -webkit-transition: opacity 5s ease-in;
    -moz-transition: opacity 5s ease-in;
    -o-transition: opacity 5s ease-in;
  }