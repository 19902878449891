.App {
  opacity: 0;
  min-height: 100vh;
  max-width: 100vw;
  transition: opacity 1s;
}

.App-loaded {
  opacity: 1;
}

.image {
  position: relative;
  overflow: hidden;
}

.required, .error {
  outline: red solid 3px;
  transition: transform 500ms ease-in;
  transform: scale(1.025);
}

.page-fade-enter main {
  min-height: 100vh;
  opacity: 0;
  
}

.page-fade-enter-active main {
  opacity: 1;
  transition: opacity 750ms ease-in;
}

.page-fade-exit main {
  min-height: 100vh;
  opacity: 1;
} 

.page-fade-exit-active main {
  opacity: 0;
  transition: opacity 750ms ease-in;
} 

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: right;
  font: normal 300 20px nazare, sans-serif;
}

li {
  margin: 8px 0;
  display: flex;
  justify-content: flex-end;
}

li:after {
  content: '';
  min-width: 8px;
  min-height: 8px;
  width: 8px;
  height: 8px;
  background: #6698CC;
  border-radius: 10px;
  margin: 10px 0 0 5px;
}

.hiddenPunctuation {
  opacity: 0;
  font-size: 10px;
}

div[tabindex="-1"] {
  outline: none;
}

.email_link {
  background: #D47C5E;
  border: 2px solid #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 100px;
  color: #ffffff;
  font: normal 400 24px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
  font-size: 24px;
  text-shadow: 1px 1px 1px #D47C5E;
  display: grid;
  place-items: center center;
  margin-bottom: 12px;
}