body {
  background-color: #6698CC;
  background-image: radial-gradient(70% 90% at bottom right, #C69838, #6698CC);
  background-size: cover;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

figure {
  overflow: hidden;
}

img {
  width: 100%;
  height: 100%;
}

.pre-img {
  background: rgba(0,0,0,0.5) url('./images/spinner.gif') no-repeat center;
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: none;
  cursor: pointer;
  transition: opacity 250ms ease-in-out, 
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  transform: scale(0.98);
}

button:focus {
  outline: 1px solid white;
  outline-offset: -2px;
}

button:active {
  transform: scale(0.95);
  opacity: 0.8;
}