.modal__message__form {
    width: 100%;
    display: grid;
    place-items: center center;
}

.modal__message__text {
    width: 100%;
    height: 30vh;
    text-align: center;
    padding: 16px;
    box-sizing: border-box;
    margin: 16px 0;
    resize: vertical;
}

.modal__message__name, .modal__message__email {
    width: 80%;
    text-align: center;
    padding: 8px 16px;
    box-sizing: border-box;
    margin: 8px;
}

.modal__message__send {
    background-color: #D8EBFE;
    font: normal 600 14px nazare, sans-serif;
    color: #D47C5E;
    width: calc(100% + 32px);
    border-radius: 0 0 10px 10px;
    text-align: center;
    padding: 8px 16px;
    box-sizing: border-box;
    margin: 0;
    margin-top: 8px;
}