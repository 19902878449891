.modal__ethics {
    padding: 32px;
    max-width: 800px;
    font: normal 300 16px work-sans, Arial, sans-serif;
}

.modal__ethics__title {
    text-align: center;
}

.modal__ethics__subtitle {
    text-align: center;
}

.modal__ethics__title {
    font-size: 24px;
}

.modal__ethics__subtitle {
    font-size: 18px;
    margin-bottom: 36px;
}