.nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -35px;
}
.nav:not(.nav--landing) {
    height: 250px;
}
.nav__a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-family: normal 700 12px delve-hand, cursive, sans-serif;
    letter-spacing: 0.102em;
    text-shadow: 1px 1px 1px #6698CC;
    text-align: center;
}
.nav__a:nth-child(1) {
    font-size: 16px;
    width: 240px;
    max-width: 70vw;
    height: 50px;
    border: none;
    cursor: auto;
}
.nav__a:nth-child(2){
    font-size: 18px;
    width: 260px;
    max-width: 80vw;
    height: 60px;
}
.nav__a:nth-child(3) {
    font-size: 14px;
    width: 220px;
    max-width: 60vw;
    height: 40px;
}
.nav__a:nth-child(4) {
    font-size: 12px;
    width: 195px;
    max-width: 50vw;
    height: 30px;
}
.nav__a:nth-child(5) {
    font-size: 10px;
    width: 150px;
    max-width: 50vw;
    height: 30px;
}

.nav__a--communication {
    background-color: #016AA3;
}
.nav__a--reiki {
    background-color: #13635E;
}
.nav__a--about {
    background-color: #668A2F;
}
.nav__a--reviews {
    background-color: #E4B766;
}
.nav__a--consultation {
    background-color: #D47C5E;
}

@media only screen and (min-width: 1060px) {
    .nav {
        flex-flow: row nowrap;
        justify-content: space-around;
    }
    .nav__a--communication {
        order: 1;
    }
    .nav__a--reiki {
        order: 2;
    }
    .nav__a--about {
        order: 3;
    }
    .nav__a--reviews {
        order: 4;
    }
    .nav__a--consultation {
        order: 5;
    }
    .nav__a:nth-child(1) {
        align-self: flex-start;
    }
    .nav__a:nth-child(2) {
        align-self: center;
        margin-top: 100px;
    }
    .nav__a:nth-child(2) {
        align-self: center;
        margin-top: -25px;
    }
    .nav__a:nth-child(3) {
        align-self: center;
        margin-top: 100px;
    }
    .nav__a:nth-child(4) {
        align-self: flex-end;
    }
}