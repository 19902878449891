.main--reviews .main__section {
    display: grid;
    flex-direction: column;
    align-items: center;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    justify-items: center;
}

.main--reviews .main__section-background--blue h4, .main--reviews .main__section-background--orange p {
    text-align: left;
}
.main--reviews .main__section-background--orange h4, .main--reviews .main__section-background--blue p {
    text-align: right;
}

.main--reviews .main__section__name-and-photo {
    display: grid;
    place-items: center center;
    padding: 0;
    margin: 0;
}

.main--reviews .main__section__photo {
    display: inline-block;

    max-width: 300px;
    max-height: 225px;
    align-self: center;
}

.main--reviews .main__section__name {
    color: #E4B766;
    font: normal 400 24px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
}

.main--reviews .main__section__text {
    font: italic 400 16px basic-sans, Verdana, sans-serif;
    white-space: pre-line;
    color: #555555;
}

@media only screen and (min-width: 768px) {
    .main--reviews .main__section {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
    .main--reviews .main__section-background--blue .main__section__name-and-photo, .main--reviews .main__section-background--orange .main__section__text {
        order: 2;
    }
    .main--reviews .main__section-background--orange .main__section__name-and-photo, .main--reviews .main__section-background--blue .main__section__text {
        order: 1
    }
    .main--reviews .main__section__name-and-photo .image {
        width: calc(40vw);
        height: calc((40vw) * 0.75);
    }
    .main--reviews .main__section__name-and-photo h4 {
        margin: 0 30px;
    }
}

@media only screen and (min-width: 1060px) {
    .main--reviews .main__section__name-and-photo .image {
        width: 300px;
        height: 225px;
    }
}