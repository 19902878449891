.modal-back {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
}

.modal {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
}

.modal__box {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    background: rgba(0,0,0,0.8);
    border-radius: 20px;
    padding-bottom: 16px;
}
.modal__box__content {
    max-width: 100%;
    max-height: 100%;
    color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
}
.modal__box__exit {
    font-size: 18px;
    padding-right: 16px;
    padding-top: 12px;
    align-self: flex-end;
    color: #ffffff;
    cursor: pointer;
}

.modal-fade {
z-index: 200;
}

.modal-fade-enter {
opacity: 0;
transform: scale(0);
}

.modal-fade-enter-active {
opacity: 1;
transform: scale(1);
transition: all 250ms ease-in;
}

.modal-fade-exit {
    position: fixed;
opacity: 1;
} 

.modal-fade-exit-active {
transform: translateX(-100vw);
opacity: 0;
transition: all 250ms ease-out;
} 