.modal__qna {
    padding: 32px;
    padding-top: 0;
    width: 70vw;
    max-width: 800px;
    font: normal 300 16px work-sans, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.modal__qna__title {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 0px;
}

.modal__qna__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.modal__qna__query {
    width: 100%;
    text-align: center;
    padding: 16px;
    box-sizing: border-box;
    margin: 16px 0;
    resize: vertical;
}

.modal__qna__answers {
    box-sizing: border-box;
    padding: 32px;
    padding-top: 0;
    display: grid;
    justify-content: flex-start;
    overflow: auto;
    height: 45vh;
    white-space: pre-wrap;
}

.modal__qna__answers__dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    background: rgba(255,255,255,0.5);
    padding: 0px 5vw;
    margin: 8px 0;
    border-radius: 8px;
    box-sizing: border-box;
}

.modal__qna__answers__dialog__q {
    padding: 8px 16px;
    background: #D47C5E;
    align-self: flex-start;
    border-radius: 10px;
    max-width: 80%;
    text-align: left;
}
.modal__qna__answers__dialog__a {
    padding: 8px 16px;
    background: #668A2F;
    align-self: flex-end;
    max-width: 80%;
    border-radius: 10px;
    text-align: right;
}

.modal__qna__name, .modal__qna__email {
    width: 80%;
    text-align: center;
    padding: 8px 16px;
    box-sizing: border-box;
    margin: 8px;
}

.modal__qna__ask {
    background-color: #D8EBFE;
    font: normal 600 14px nazare, sans-serif;
    color: #D47C5E;
    width: calc(100% + 10vw);
    border-radius: 0 0 10px 10px;
    text-align: center;
    padding: 8px 16px;
    box-sizing: border-box;
    margin: 0;
    margin-top: 16px;
}

.modal__qna__complete {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.modal__qna__complete__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modal__qna__complete__exit {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    color: #333333;
}