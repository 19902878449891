.header--landing {
  z-index: 50;
}

.nav--landing {
  z-index: 100;
  min-height: calc(80vh - 170px);
  top: 0;
  position: relative;
}

.page-fade-exit .nav--landing {
  opacity: 1;
} 

.page-fade-exit-active .nav--landing {
  opacity: 0;
  transition: opacity 750ms ease-in;
} 

.nav--landing .nav__a:nth-child(1) {
  font-size: 16px;
  width: 260px;
  height: 60px;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.nav--landing .nav__a:nth-child(2) {
  font-size: 15px;
  width: 240px;
  height: 50px;
  max-width: 65vw;
}
.nav--landing .nav__a:nth-child(3) {
  font-size: 13px;
  width: 220px;
  height: 45px;
}
.nav--landing .nav__a:nth-child(4) {
  font-size: 11px;
}
.nav--landing .nav__a:nth-child(5) {
  font-size: 9px;
}
@media only screen and (min-width: 450px) and (min-height: 400px) {
  .nav--landing {
    position: absolute;
    top: calc(60vh - 73px);
    min-height: 0;
    right: 0;
    width: 450px;
  }
}
@media only screen and (min-width: 780px) {
  .nav--landing {
    position: absolute;
    top: calc(60vh - 155px);
    min-height: 0;
    right: calc(30vw - 150px);
    width: 280px;
  }
}
@media only screen and (min-width: 1060px) {
  .nav--landing {
    flex-direction: column;
    justify-content: center;
    top: calc(80vh - 200px);
  }
  .nav--landing .nav__a {
    align-self: center;
    margin: 8px;
  }
}

#clouds__viewport {
  bottom: 0;
  left: 0;
  overflow: hidden;
  perspective: 400;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
}

#clouds__world {
  z-index: 10;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

#clouds__world div { 
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
}

.clouds__cloudBase {
  height: 20px;
  width: 20px;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  left: -25vw;
  top: 256px;
}

.clouds__cloudBase--right {
  left: 100vw;
}

.clouds__cloudBase--expired {
  opacity: 0;
  -webkit-transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;
}

.clouds__cloudBase__cloudLayer {
  height: 256px;
  left: 50%;
  margin-left: -128px;
  margin-top: -128px;
  position: absolute;
  top: 50%;
  width: 256px;

  -webkit-transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;
}