.main {
    margin: 0;
    font: normal 400 14px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
    color: #333333;
}

.main__section {
    max-width: 1000px;
    padding: 20px 5vw;
    text-align: right;
    margin: 0 auto;
    font-size: 16px;
    height: 100%;
}
.main__section__p, .main__section>p {
    line-height: 32px;
}
.main__section-background:first-child {
    padding-top: 32px;
}
.main__section-background:last-child {
    padding-bottom: 80px;
}
.main__section__headline, .main__section__p__headline {
    font: normal 400 24px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
    font-size: 28px;
}

.main__section__list-heading {
    font: normal 600 14px nazare, sans-serif;
}

.main__section-background--blue {
    background-color: #D8EBFE;
}

.main__section-background--orange {
    background-color: #FBF3E1;
}

button {
    cursor: pointer;
}

.main__section__button {
    background: #808080;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 8px;
    width: 220px;
    height: 50px;
}

.main__section__button:hover {
cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .main__section {
        font-size: 18px;
    }
    .main__section__headline, .main__section__p__headline {
        font-size: 32px;
    }
}