.modal--Booking .modal__box {
    padding-bottom: 0;
}

.modal__booking-stage {
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__booking-stage__form {
    box-sizing: border-box;
    width: 90vw;
    max-width: 800px;
    height: calc(80vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding: 32px;
}


.modal__booking-stage__form--about {
    text-align: right;
    justify-content: flex-end;
}

.modal__booking-stage__form--about h3 {
    text-align: right;
}

.modal__booking-stage__form--about ul {
    font-size: 14px;
    color: #E1E1E1;
}

.modal__booking-stage__form--about li:after {
    margin: 4px 0 0 8px;
  }

.modal__booking-stage__form--payment {
    height: 100%;
    padding: 32px;
}

.modal__booking-stage__form__payment-button {
    display: grid;
    place-items: center center;
    z-index: 2000;
    cursor: pointer;
}

.modal__booking-stage__payment__alt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__booking-stage__payment__alt__cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px;
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 12px;
    border-radius: 10px;
    font-family: normal 700 12px delve-hand, cursive, sans-serif;
    font-size: 10px;
    letter-spacing: 0.102em;
    text-shadow: 1px 1px 1px #6698CC;
    cursor: pointer;
}

.modal__booking-stage__form textarea:not(:focus), .modal__booking-stage__form input:not(:focus) {
    text-align: center;
}

.modal__booking-stage__form textarea, .modal__booking-stage__form input {
    text-align: left;
    padding: 0 16px;
    box-sizing: border-box;
}

.modal__booking-stage__form select {
    min-width: 80px;
}

.modal__booking-stage__form select.placeholdered { color: gray; }

.modal__booking-stage__nav {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: stretch;
}

.modal__booking-stage__nav button {
    flex-basis: 50%;
    background: #ffffff;
}
.modal__booking-stage__nav button:disabled {
    background: #E1E1E1;
    opacity: 0.6;
    cursor: default;
}
.modal__booking-stage__nav button:first-child {
    border-bottom-left-radius: 20px;
}
.modal__booking-stage__nav button:last-child {
    border-bottom-right-radius: 20px;
}


.modal__booking-stage__form--animal-info .modal__booking-stage__form__photo-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 260px;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__photo-upload__area {
    position: relative;
    width: 180px;
    height: 180px;
    border: 1px solid #898989;
    
    color: #333333;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__photo-upload__area::before {
    content: '';
    position: absolute;
    top: 0;
    width: 180px;
    height: 180px;
    background: rgba(255,255,255,0.8) url(../images/photoupload.png) center no-repeat;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__photo-upload__area__img {
    position: absolute;
    width: 180px;
    height: 180px;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__photo-upload__area input[type=file] {
    position: absolute;
    top: 0;
    margin: 0;
    color: #D47C5E;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 89%;
    z-index: 150;
    cursor: pointer;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__photo-upload__info {
    width: 180px;
    text-align: center;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__animal-info {
    padding: 16px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
}

.modal__booking-stage__form--animal-info input, 
.modal__booking-stage__form--animal-info select {
    height: 30px;
    border: none;
    margin: 4px 4px;
}

.modal__booking-stage__form--animal-info select {
    text-align-last: center;
    cursor: pointer;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__background-info {
    flex-basis: 100%;
    padding: 16px;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__animal-info__text-area, 
.modal__booking-stage__form--animal-info .modal__booking-stage__form__background-info__text-area {
    height: 6em;
    margin: 4px 0;
    width: 100%;
    overflow: auto;
    text-align: left;
    box-sizing: border-box;
    padding: 16px 32px;
    resize: none;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__background-info__text-area { 
    height: 120px;
}

.modal__booking-stage__form--animal-info .modal__booking-stage__form__animal-info__age {
    width: 50%;
    margin-right: 6px;
    text-align: center;
    box-sizing: border-box;
}

.modal__booking-stage__form--focus {
    align-content: space-around;
}

.modal__booking-stage__form--focus .modal__booking-stage__form__animal-info__text-area {
    flex-basis: 100%;
    height: 40%;
    padding: 16px 32px;
    margin: 16px 0;
    box-sizing: border-box;
}

.modal__booking-stage__form--your-info {
    display: flex;
    flex-flow: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    row-gap: 12px;
}

.modal__booking-stage__form--sending {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
}

.modal__booking-stage__form--sending > div {
    box-sizing: border-box;
    width: 100%;
    background: rgba(216, 235, 254, 0.2);
    border: 16px solid rgba(216, 235, 254, 0);
    border-radius: 12px 12px 0 0;
    overflow-y: auto;
    height: 100%;
    backdrop-filter: blur(12px);
}

.modal__booking-stage>div {
    position: relative;
}

.booking-fade {
z-index: 200;
}
    
.booking-fade-forwards-enter {
opacity: 0;
}

.booking-fade-backwards-enter {
opacity: 0;
}

.booking-fade-forwards-enter-active, .booking-fade-backwards-enter-active {
transform: translate(0vw, 0vw);
opacity: 1;
transition: all 750ms ease-in;
}

.booking-fade-forwards-exit, .booking-fade-backwards-exit {
opacity: 1;
} 

.booking-fade-forwards-exit-active {
position: absolute;
top: 0;
left: 0;
transform: translate(-100vw, 0vw);
transition: all 1250ms ease-out;
opacity: 0;
} 

.booking-fade-backwards-exit-active {
position: absolute;
top: 0;
left: 0;
transform: translate(100vw, 0vw);
transition: all 1250ms ease-out;
opacity: 0;
} 

.modal__booking-stage--Payment .modal__booking-stage__nav, .modal__booking-stage--Confirmation .modal__booking-stage__nav {
    display: none;
}

.modal__booking-stage__form--confirmation {
    height: 100%;
    background-image: radial-gradient(70% 90% at bottom right, rgba(255,255,255,0.5), rgba(0,0,0,0.1));
    font: normal 400 14px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
    text-align: center;
}

@media only screen and (min-height: 520px) {
    .modal__booking-stage__form--payment {
        display: block;
    }
}

.modal__booking-stage--Start .modal__booking-stage__form .select_service  {
    margin: 12px;
    text-align: center;
    width: 80%;
}
.modal__booking-stage--Start .modal__booking-stage__form .select_service:active  {
    text-align: left;
}