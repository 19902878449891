.main--consultation .main__section-background--blue {
    padding-top: 0;
}

.main--consultation .main__section {
    position: relative;
}

.main--consultation .main__section-background--blue .main__section {
    padding: 60px 20px 0 20px;
}

.main--consultation .main__section-background:last-child {
    padding: 80px 0;
}

.main--consultation .main__section__button--book {
    background: #016AA3;
    border: 3px solid #ffffff;
    border-radius: 8px;
    width: 300px;
    height: 100px;
    color: #ffffff;
    font: normal 400 24px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
    font-size: 32px;
    position: absolute;
    left: calc(50% - 150px);
    top: calc(60px - 50px);
    text-shadow: 1px 1px 1px #016AA3;
    z-index: 50;
}

.main__section-background__img--Elephant {
    width: 100%;
    height: 37vw;
}

.main--consultation .main__section--info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;
    min-height: 20vh;
    
}

.main--consultation .main__section__button--questions, .main--consultation .main__section__button--code-of-ethics {
    background: #668A2F;
    margin-bottom: 16px;
    border-color: #D47C5E;
    font: normal 400 24px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
    font-size: 18px;
}
.main--consultation .main__section__button--questions { background: #668A2F; }
.main--consultation .main__section__button--code-of-ethics { background: #E4B766; }

@media only screen and (max-width: 320px) { 
    .main--consultation .main__section__button--book {
        background: #016AA3;
        border: 3px solid #ffffff;
        border-radius: 8px;
        width: 200px;
        height: 80px;
        color: #ffffff;
        font: normal 400 24px dapifer-stencil, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;
        font-size: 24px;
        position: absolute;
        left: calc(50% - 100px);
        top: calc(60px - 40px);
        text-shadow: 1px 1px 1px #016AA3;
        z-index: 50;
    }
}

@media only screen and (min-width: 720px) { 
    .main--consultation .main__section-background:last-child {
        padding: 0;
    }
}

@media only screen and (min-width: 1060px) { 
    .main--consultation .main__section__button--book {
        width: 400px;
        height: 130px;
        font-size: 38px;
        position: absolute;
        left: calc(50% - 200px);
        top: calc(80px - 65px);
    }
    .main--consultation .main__section-background--blue .main__section {
        padding: 80px 20px 0 20px;
    }
    .main--consultation .main__section__button--questions, .main--consultation .main__section__button--code-of-ethics {
        width: 260px;
        height: 80px;

    }
}

.modal-fade {
z-index: 200;
}

.modal-fade-enter {
opacity: 0;
transform: scale(0);
}

.modal-fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 250ms ease-in;
}

.modal-fade-exit {
background: transparent;
opacity: 1;
} 

.modal-fade-exit-active {
background: transparent;
transform: translateX(-100vw);
opacity: 0;
transition: all 250ms ease-out;
} 